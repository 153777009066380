<template>
  <div></div>
</template>

<script>
import pdfMake from "pdfmake/build/pdfmake.min.js";
import pdfFonts from "pdfmake/build/vfs_fonts.js";
export default {
  name: "Export",
  props: {
    appData: Object,
  },
  mounted() {
    this.createPdf();
    this.$emit("finished");
  },
  methods: {
    getShortEducation(user) {
      if (user.education.toLowerCase().includes("geologin")) {
        return "Geologin";
      } else if (user.education.toLowerCase().includes("geol")) {
        return "Geologe";
      } else if (user.education.toLowerCase().includes("geografin")) {
        return "Geografin";
      } else if (user.education.toLowerCase().includes("geogr")) {
        return "Geograf";
      } else if (user.education.toLowerCase().includes("bauingenieur")) {
        return "Bauingenieur";
      } else if (user.education.toLowerCase().includes("disp")) {
        return "Disponent";
      }
      return user.education;
    },
    createPdf() {
      let users = [
        [
          { text: "Funktion", bold: true },
          { text: "Name", bold: true },
          { text: "Vorname", bold: true },
          { text: "Ausbildung", bold: true },
          //{ text: "Telefon Büro", bold: true },
          { text: "Mobile", bold: true },
          { text: "E-Mail", bold: true },
          { text: "Basisschulung erhalten", bold: true },
          { text: "Letztes Update", bold: true },
        ],
      ];
      let leaders = true;
      this.appData.users.forEach((user) => {
        let mainRoleLabel = leaders ? user.mainRole.label : "";
        if (leaders && user.mainRole.name === "transitgas-staff") {
          leaders = false;
          users.push(["", "", "", "", "", "", "", ""]);
        }
        users.push([
          { text: mainRoleLabel, bold: true },
          user.familyname,
          user.firstname,
          this.getShortEducation(user),
          //user.phone,
          user.mobile,
          user.email,
          user.trgBaseEducation || "",
          user.trgLastEducation || "",
        ]);
      });
      pdfMake.vfs = pdfFonts.pdfMake.vfs;
      let now = new Date();
      let docDef = {
        pageSize: "A4",
        pageOrientation: "landscape",
        content: [
          { text: "Transitgas Pikettdienst Naturgefahren", style: "header" },
          { text: "Kontakte und Schulungsnachweis", style: "header" },
          {
            text: `Stand: ${now.toLocaleDateString("de-CH", {
              day: "numeric",
              month: "long",
              year: "numeric",
            })}`,
            margin: [0, 5, 0, 10],
          },
          {
            table: {
              headerRows: 1,
              widths: [70, 80, 70, 100, 70, "*", 70, 70],
              body: users,
            },
          },
        ],
        defaultStyle: {
          fontSize: 9,
        },
        styles: {
          header: {
            fontSize: 12,
            bold: true,
          },
        },
      };
      pdfMake.createPdf(docDef).download("Kontakte_Schulungsnachweis.pdf");
      this.$emit("finished");
    },
  },
};
</script>